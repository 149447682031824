.app__testimonial {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__testimonial-item {
    width: 55%;
    height:450px;
    background-color: var(--white-color);
    display: flex;
    flex-direction: row;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  
    transition: all 0.3s ease-in-out;

  
    @media screen and (min-width: 2000px) {
      min-height: 450px;
  
      img {
        width: 150px;
        height: 150px;
      }
    }
  
    @media screen and (max-width: 850px) {
      width: 100%;
    }
  
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  
  .app__testimonial-content {
    flex: 1;
    height: 100%;
    padding: 0 2rem;
    text-align: left;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  
    p {
      font-size: 1.25rem;
      line-height: 2rem;
      color: var(--black-color);
      font-family: var(--font-base);
  
      @media screen and (min-width: 2000px) {
        font-size: 2rem;
        line-height: 3.5rem;
      }
    }
  
    h4 {
      font-weight: 600;
      color: var(--secondary-color);
      margin-top: 2rem;
    }
  
    h5 {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }
  
    @media screen and (max-width: 600px) {
      margin-top: 2rem;
      padding: 0;
    }
  }
  
  .app__testimonial-btns {
    flex-direction: row;
    margin-top: 1rem;
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--white-color);
  
      margin: 1rem;
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 20px;
        height: 20px;
        color: var(--secondary-color);
      }
  
      &:hover {
        background-color: var(--secondary-color);
  
        svg {
          color: var(--white-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 100px;
        height: 100px;
  
        svg {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
  
  .app__testimonial-brands {
    width: 80%;
    flex-wrap: wrap;
    margin-top: 2rem;
  
    div {
      width: 150px;
      margin: 1.5rem;
  
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        filter: grayscale(1);
      }
  
      &:hover {
        img {
          filter: grayscale(0);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 210px;
        margin: 2rem;
      }
  
      @media screen and (max-width: 450px) {
        width: 120px;
        margin: 1rem;
      }
    }
  
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
  

.app_testimonial-main {
  width: 70%;

    height: 100%;
  // background-color: aqua;
  .app_testimonial-main-img {
    position: relative;
  }
  .app_testimonial-main-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    transition: all 0.3s ease;

    .icon7{
      color: white;
      width: 50px;
    }
  }

 
  .app-testimonial-name {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    h2 {
      text-align: center;
    }

    p {
      font-size: 0.9rem;
      color: gray;
      text-align: center;
    }
  }
  img {
    width: 100%;
    height: 355px;
  }
 
  img:hover + .app_testimonial-main-img-overlay {
    display: flex;
    transition: all 0.9s ease;
    
  }
 
}


.icon7 {
  rotate: 45deg;
}
.app_testimonial-main-img-overlay:hover {
  display: flex;
}


@media screen and (max-width: 1600px) {
  
  .app__testimonial-item {
  }
.app_testimonial-main {
    width: 100%;
    height: fit-content;
    img {
      width: 100%;
      height: 100%;
    }
  }
  
}
.fofo {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .momo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .app__testimonial-item {
    width: 50%;
    height: fit-content;
    .app_testimonial-main {
      width: 100%;
      .app_testimonial-main-img {
        width: 100%;
        img {
          width: 100%;
          height: 420px;
        }
      }
    }
  }
  }
}

@media screen and (max-width: 1200px) {
  .fofo {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .momo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .app__testimonial-item {
      width: 80%;
      height: fit-content;
      .app_testimonial-main {
        width: 100%;
        .app_testimonial-main-img {
          width: 100%;
          img {
            width: 100%;
            height: 500px;
          }
        }
      }
    }
    }
  }
  
}

@media screen and (max-width: 800px) {
  .fofo {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .momo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .app__testimonial-item {
      width: 100%;
      height: fit-content;
      .app_testimonial-main {
        width: 100%;
        .app_testimonial-main-img {
          width: 100%;
          img {
            width: 100%;
            height: 380px;
          }
        }
      }
    }
    }
  }
  
}

@media screen and (max-width: 420px) {
  .fofo {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .momo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .app__testimonial-item {
      width: 100%;
      height: fit-content;
      .app_testimonial-main {
        width: 100%;
        .app_testimonial-main-img {
          width: 100%;
          img {
            width: 100%;
            height: 300px;
          }
        }
      }
    }
    }
  }
}